.base {
    min-width: 140px;
    @apply flex items-center justify-center
    text-base
    px-10 
    rounded-md 
    h-11
    transition-all 
    cursor-pointer
    shadow-md

    disabled:opacity-50;
}
.primary {
    @apply bg-black
   text-white

   hover:bg-gray-800;
}

.secondary {
    @apply bg-white
    border
    border-black
    text-black
 
    hover:bg-black
    hover:text-white;
}

.danger {
    @apply bg-red-600
    border
    border-red-600
    text-white
 
    hover:bg-red-500
    hover:text-white;
}
    
.success {
    @apply bg-green-600
    border
    border-green-600
    text-white
 
    hover:bg-green-500
    hover:text-white;
}

.link {
    @apply min-w-0
    bg-none
    shadow-none
    p-0
    h-auto
    inline-block text-gray-700
    hover:text-gray-500
    text-xs
    underline
    font-medium;
}

.link_danger {
    @apply min-w-0
    bg-none
    shadow-none
    p-0
    h-auto
    inline-block
    text-red-600

    text-xs
    underline
    font-medium;
}
