@tailwind base;
@tailwind components;
@tailwind utilities;
 

.filelist {
    height: 75vh;
    @apply overflow-scroll
}


.card-item {overflow: hidden;}
.card-item:hover {background-color:#403aa0;}
.card-item:before {content: '';position: absolute;left: -30px;top: -4px;background: #ede1fc;width: 80px;height: 80px;border-radius: 50px;z-index: 1;}
.card-item * {position: relative;z-index: 2;}
